<template>
  <a-layout id="guest-onboarding-layout">
    <a-layout-header class="guest-onboarding-header">
      <div class="logo">
        <img src="/partners/sims-logo.png" alt="Logo" />
      </div>
    </a-layout-header>
    <a-layout-content :style="{ background: '#3A0BA3', padding: '30px', overflow: 'auto' }">

      <div class="inner">
        <h1 class="intro">Streamline your school-day.</h1>

        <a-row class="welcome" :gutter="60">
          <a-col :span="12">

            <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
              <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                <div class="wistia_embed wistia_async_st4vn95h3e seo=true videoFoam=true"
                  style="height:100%;position:relative;width:100%">
                  <div class="wistia_swatch"
                    style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                    <img src="
https://fast.wistia.com/embed/medias/st4vn95h3e/swatch"
                      style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true"
                      onload="this.parentNode.style.opacity=1;" />
                  </div>
                </div>
              </div>
            </div>

          </a-col>

          <a-col :span="12">
            <p>
              teamSOS is the affordable & innovative all-device app that streamlines your everyday & emergency incident
              management in real-time.
            </p>
            <p>
              Empower your school staff with the ability to:
            </p>
            <ul>
              <li>
                Instantly brief and mobilise the right support teams with one-tap.
              </li>
              <li>
                Communicate dynamically with innovation that replaces radio & facilitates all-staff co-ordination when
                needed.
              </li>
              <li>
                Access your guidance & protocols in-the-moment - instantly.
              </li>
              <li>
                Evidence effortlessly with instant rich-media incident reports.
              </li>
              <li>
                Harness data-driven insights to identify patterns & refine strategies.
              </li>
            </ul>

            <div class="testimonial">
              <div class="left">
                <img src="/partners/sims/a++.png" alt="">
              </div>
              <div class="right">
                "The impact has been <b>transformational.</b> Schools & trusts up and down the country need to know about
                teamSOS."
                <div class="from">
                  David Reynolds, Deputy Head, Cannock Chase High.
                </div>
              </div>
            </div>

          </a-col>
        </a-row>

        <div class="getting-started">
          <p>
            Now in partnership with SIMS, launch a teamSOS incident directly from the SIMS interface!
          </p>
          <div class="getting-started-bottom">
            <a href="#" @click.prevent="goToSetup">
              Set up your organisation
            </a>
            <img class="free-trial-text" src="/partners/sims/no-obligation-free-trial.png" alt="">
          </div>
        </div>

        <div class="features-section">
          <a-row :gutter="50" type="flex">
            <a-col :span="12" class="left-col">
              <p>
                🕒 Slash wasted on-call time <br>
                🎯 Target & tackle your schools priorities <br>
                📞 Say goodbye to the endless cycle of office oordination calls <br>
                ✅ Remove the burden of manual incident reporting <br>
                🔄 Now launching incidents directly out of your SIMS interface
              </p>
              <div class="cta-wrapper">
                <a href="#" @click.prevent="goToSetup">
                  Set up your organisation
                </a>
              </div>
            </a-col>
            <a-col :span="12">
              <img class="phone-image" src="/partners/sims/iphone.png" alt="">
            </a-col>
          </a-row>
        </div>

        <div class="second-features-section">
          <img src="/partners/sims/bottom.png" alt="">

          <div class="cta-wrapper">
            <a @click.prevent="goToSetup">
              Set up your organisation
            </a>
          </div>
        </div>

      </div>

    </a-layout-content>
  </a-layout>
</template>

<script>

export default {
  mounted() {
    // add the above scripts to the dom
    const script = document.createElement('script');
    script.src = 'https://fast.wistia.com/embed/medias/st4vn95h3e.json';

    const script2 = document.createElement('script');
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';

    document.body.appendChild(script);
    document.body.appendChild(script2);
  },

  created() {
  },

  methods: {
    goToSetup() {
      this.$router.push({
        path: '/guest-onboarding/create-account',
        query: this.$route.query
      });
    }
  }
}
</script>

<style scoped lang="scss">
#guest-onboarding-layout {
  // min-height: 100vh;
  background: #3A0BA3;
  overflow-y: scroll !important;
  height: 100vh;
  max-height: 100vh;

  .intro {
    text-align: center;
    font-size: 2.3em;
    margin-bottom: 40px;
    color: #fff;
    font-family: 'Quicksand';
    font-weight: 500;
  }

  .inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    ul {
      padding-left: 17px;
    }

    p,
    li {
      font-size: 16px;
      color: #fff;
      font-family: 'Quicksand';
    }

    ul li+li {
      margin-top: 5px;
    }
  }


  .getting-started-btn {
    width: 100%;
    padding: 25px;
    line-height: 0px;
    margin-top: 15px;
  }

  .guest-onboarding-header {
    background: #3A0BA3;
    text-align: center;

    height: 80px;
    line-height: 80px;

    .logo {
      height: 50px;

      img {
        max-height: 100%;
      }
    }
  }

  .testimonial {
    margin-top: 35px;
    display: flex;

    color: #fff;
    font-family: 'Quicksand';

    .left {
      flex-shrink: 1;
      display: flex;
      align-items: center;

      img {
        height: 50px;
      }
    }

    .right {
      flex-grow: 1;
      padding-left: 5px;
      text-align: right;

      font-size: 17px;

      .from {
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }

  .getting-started {
    text-align: center;
    margin-top: 50px;

    p {
      margin-bottom: 80px;
      display: block;
    }

    a {
      color: #fff;
      padding: 15px 40px;
      border-radius: 25px;
      font-family: 'Quicksand';
      border: 2px solid #fff;
      font-weight: 500;
      transition: all 0.2s ease-in-out;
      margin-left: 10px;
      margin-right: 10px;

      &:hover {
        color: #3A0BA3;
        background: #fff;
      }
    }

    .getting-started-bottom {
      position: relative;

      .free-trial-text {
        position: absolute;
        height: 43px;
        top: -100%;
      }
    }
  }

  .features-section {
    padding-top: 100px;
    padding-bottom: 40px;

    .left-col {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .cta-wrapper {
        padding-top: 30px;
      }

      a {
        color: #fff;
        padding: 15px 40px;
        width: 100%;
        border-radius: 25px;
        font-family: 'Quicksand';
        border: 2px solid #fff;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        margin-left: 10px;
        margin-right: 10px;

        &:hover {
          color: #3A0BA3;
          background: #fff;
        }
      }
    }

    p {
      font-size: 16px;
      color: #fff;
      font-family: 'Quicksand';
      font-weight: 400;
      line-height: 2;
      margin-bottom: 20px;
    }

    .phone-image {
      width: 100%;
    }
  }

  .second-features-section {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 120px;

    img {
      margin-left: auto;
      margin-right: auto;
    }

    .cta-wrapper {
      padding-top: 80px;
    }

    a {
      color: #fff;
      padding: 15px 40px;
      border-radius: 25px;
      font-family: 'Quicksand';
      border: 2px solid #fff;
      font-weight: 500;
      transition: all 0.2s ease-in-out;
      margin-left: 10px;
      margin-right: 10px;

      &:hover {
        color: #3A0BA3;
        background: #fff;
      }
    }
  }
}
</style>